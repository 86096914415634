:root {
  --goboat-blue: #2e3d5c;
  --goboat-yellow: #ffb500;
  --goboat-orange: #a47141;
  --goboat-red: #e55457;
  --goboat-green: #77a846;
  --goboat-gray: #f1f1f1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#experience {
  align-items: flex-start;
}
@font-face {
  font-family: 'Lato-Bold';
  font-display: swap;
  src: url("3aefd2894f983774da3dd82ec97ececc.ttf") format('truetype');
}
@font-face {
  font-family: 'Lato-Regular';
  font-display: swap;
  src: url("92da6eebdf4ecfd80e96b9c0f1cd7305.ttf") format('truetype');
}
@font-face {
  font-family: 'Lato-Light';
  font-display: swap;
  src: url("b05792014883ff04a654c5ff766bab8e.ttf") format('truetype');
}
@font-face {
  font-family: 'GearedSlab';
  font-display: swap;
  src: url("a05396098f7f31da7e09ebc4036bc7d0.ttf") format('truetype'), url("90f6b27f3f1368846c95202be67ba1d7.woff2") format('woff2');
}
html {
  font-size: 18px;
}
h1 {
  font-family: GearedSlab, "Arial Narrow", Arial, sans-serif;
  font-size: 4rem;
  color: #2e3d5c;
  text-transform: uppercase;
  letter-spacing: 1.11px;
  font-weight: normal;
}
h1 a {
  color: inherit;
}
h2 {
  font-family: GearedSlab, "Arial Narrow", Arial, sans-serif;
  font-size: 3rem;
  color: #2e3d5c;
  text-transform: uppercase;
  letter-spacing: 1.11px;
  margin-bottom: 0.5rem;
  font-weight: normal;
}
h2 a {
  color: inherit;
}
h3 {
  font-family: GearedSlab, "Arial Narrow", Arial, sans-serif;
  font-size: 2.5rem;
  color: #2e3d5c;
  text-transform: uppercase;
  letter-spacing: 1.11px;
  margin-bottom: 0.5rem;
  font-weight: normal;
}
h3 a {
  color: inherit;
}
h4 {
  font-family: GearedSlab, "Arial Narrow", Arial, sans-serif;
  font-weight: normal;
}
p {
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
  font-size: 0.89rem;
  color: #2e3d5c;
  line-height: 1.5em;
}
p a {
  color: inherit;
  font-family: Lato-Bold, Geneva, Verdana, sans-serif;
}
p a:hover {
  border-bottom: 1px solid;
}
label {
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  color: #2e3d5c;
}
li {
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
  font-size: 1rem;
}
button {
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
  font-size: 1rem;
}
.nav li {
  font-family: GearedSlab, "Arial Narrow", Arial, sans-serif;
  font-size: 1rem;
  color: #2e3d5c;
  text-transform: uppercase;
  letter-spacing: 1.11px;
  list-style: none;
}
a:link,
a:visited {
  text-decoration: none;
  outline: none;
  border: 0;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  p {
    text-align: center;
  }
}
#footer {
  display: flex;
  flex-direction: row;
  padding: 5% 0;
  background-color: #3d527a;
  color: #fff;
  justify-content: space-between;
}
#footer .footer_element {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 5%;
  word-wrap: normal;
}
#footer .footer_element img {
  object-fit: contain;
}
#footer .footer_element h3 {
  position: relative;
  color: inherit;
  font-size: 1rem;
  padding: 0;
  padding-bottom: 12px;
  margin: 0;
  margin-bottom: 8px;
}
#footer .footer_element h3:after {
  content: "";
  margin: 0;
  border: 0;
  height: 5px;
  background-image: url("26ef9230c3d493ef9ebc31c0d06c0be7.svg");
  background-repeat: repeat;
  background-size: 10px 10px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
#footer .footer_element p,
#footer .footer_element li {
  font-family: Lato-Light, Geneva, Verdana, sans-serif;
  color: inherit;
  font-size: 0.7rem;
  font-weight: lighter;
  list-style-type: none;
  letter-spacing: 0.5px;
  line-height: 1rem;
}
#footer .footer_element p,
#footer .footer_element li {
  color: $goboat-white;
  text-transform: uppercase;
}
#footer .footer_element a {
  color: #ffb500;
  text-transform: uppercase;
}
#footer .footer_element li {
  width: fit-content;
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
  border-bottom: 1px solid transparent;
}
#footer .footer_element a:hover li {
  border-bottom: 1px solid;
}
#footer .footer_element #footer_shortcuts_socmed {
  margin-top: 25px;
  display: flex;
  box-sizing: content-box;
  justify-content: center;
}
#footer .footer_element #footer_shortcuts_socmed .socmed_icon {
  width: 100%;
}
#footer .footer_element #footer_shortcuts_socmed .socmed_link {
  width: 40px;
  max-height: 80px;
  margin: 5px 10px;
  margin-left: 0;
}
#footer .footer_element #footer_contact_content p a {
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
}
#footer .footer_element a {
  text-decoration: none;
  color: #fff;
}
#footer .footer_col:not(:last-child) {
  margin-bottom: 30px;
}
#footer .footer_element:first-child {
  width: 30%;
  padding-right: 0;
}
@media screen and (max-width: 767px) {
  #footer {
    padding: 10% 0;
    flex-direction: column;
    text-align: center;
  }
  #footer .footer_element {
    width: 100%;
    margin-bottom: 40px;
  }
  #footer .footer_element h3 {
    font-size: 1.2rem;
  }
  #footer .footer_element #footer_shortcuts li {
    margin: 20px 0 20px 0;
  }
  #footer .footer_element #footer_shortcuts a:last-child li {
    margin: 20px 0 0 0;
  }
  #footer .footer_element #footer_shortcuts_content li {
    width: auto;
    margin: 0;
    padding: 15px 0;
  }
  #footer .footer_element:first-child {
    padding: 0 5%;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
#header {
  height: auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
#header .flexer .logoImg {
  width: 80%;
}
#header .flexer h1 {
  font-size: 3rem;
}
#header .header_hr {
  width: 320px;
  margin: 10px;
}
.blur-1 {
  filter: blur(1px);
}
.blur-2 {
  filter: blur(2px);
}
.blur-3 {
  filter: blur(3px);
}
.blur-4 {
  filter: blur(4px);
}
.blur-5 {
  filter: blur(5px);
}
.blur-6 {
  filter: blur(6px);
}
.blur-7 {
  filter: blur(7px);
}
.blur-8 {
  filter: blur(8px);
  width: calc(100% + 30px) !important;
  height: calc(100% + 30px) !important;
  margin: -15px !important;
}
.blur-9 {
  filter: blur(9px);
}
.blur-10 {
  filter: blur(10px);
}
@media screen and (max-width: 767px) {
  .top {
    justify-content: flex-start !important;
  }
}
#intro {
  background-color: #f1f1f1 !important;
  padding: 5% 10%;
  margin: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
#locations {
  padding-top: 5% 0;
  padding: 0;
  margin: 30px;
}
.row {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: fit-content;
  justify-content: flex-start;
}
.row .location {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 0 0 calc(100% / 2);
  -ms-flex: auto;
  padding: 10px;
  filter: drop-shadow(0 4px 6px rgba(50,50,93,0.11));
  transition: 0.3s;
  margin-top: 80px;
  filter: drop-shadow(0 0 10px #789);
}
.row .location .content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px 30px;
  border-radius: 0 0 20px 20px;
  margin-top: -5px;
}
.row .location .content-container h3 {
  text-align: center;
  margin-bottom: 0;
  font-size: 2.5rem;
}
.row .location .content-container p {
  width: 100%;
  text-align: left;
/* overflow hidden
        display -webkit-box
        -webkit-line-clamp 6
        -webkit-box-orient vertical */
}
.row .location .content-container hr {
  color: #f1f1f1;
  border-top: 10px dotted #a47141;
  margin-bottom: 15px;
  width: 100%;
}
.row .location .logo {
  position: absolute;
  z-index: 4;
}
.row .location .round-corner {
  border-radius: 20px 20px 0 0;
}
.row .location:hover,
.row .location:active {
  transform: scale(1.02);
  cursor: pointer;
}
.row .coming-soon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 182px;
  height: 154px;
  overflow: hidden;
  font-family: 'Lato-Bold';
}
.row .coming-soon span {
  position: absolute;
  top: 45px;
  left: -43px;
  padding: 6px 40px;
  color: #fff;
  text-transform: uppercase;
  transform: rotate(-45deg);
  background: linear-gradient(#3b4d73 0%, var(--goboat-blue) 100%);
  box-shadow: 0 0 20px 0px rgba(0,0,0,0.38);
}
@media screen and (min-width: 1251px) {
  .row a {
    flex-basis: calc(100% / 2) !important;
  }
  .row .location {
    padding: 10px 20px;
  }
  .row .location .background-image {
    height: 250px;
  }
  .row .location .logo {
    width: 150px;
    top: -65px;
  }
  .row .coming-soon {
    left: 20px;
  }
}
@media screen and (max-width: 1250px) {
  a {
    flex-basis: calc(100% / 2) !important;
  }
  .location .background-image {
    height: 250px;
  }
  .location .logo {
    width: 150px;
    top: -65px;
  }
}
@media screen and (max-width: 992px) {
  .row .coming-soon span {
    top: 35px;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 767px) {
  .row {
    margin-top: 0;
  }
  .row a {
    flex-basis: 100% !important;
  }
  .row .location {
    padding: 10px 0;
    margin-top: 78px;
  }
  .row .location .background-image {
    height: 150px;
  }
  .row .location .logo {
    width: 130px;
    top: -56.333333333333336px;
  }
  .row .coming-soon {
    left: 0px;
  }
  .row .coming-soon span {
    top: 30px;
    font-size: 0.7rem;
  }
}
@media screen and (min-width: 1251px) {
  #locations {
    padding: 5% 10%;
  }
}
#partner {
  background-color: #f1f1f1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 10%;
  margin: 35px;
  text-align: center;
  justify-content: center;
}
#partner * {
  color: #a47141;
}
#partner #mc_embed_signup {
  margin-top: 50px;
  width: 50%;
}
#partner #mc_embed_signup #mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
  text-align: left;
}
#partner #mc_embed_signup #mc_embed_signup_scroll input,
#partner #mc_embed_signup #mc_embed_signup_scroll button {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Lato-Regular, Geneva, Verdana, sans-serif;
  font-size: 0.89rem;
  color: #2e3d5c;
  margin-top: 10px;
}
#partner #mc_embed_signup #mc_embed_signup_scroll button {
  height: 58px;
  font-size: 1rem;
  background-color: #2e3d5c;
  color: #fff;
  margin-top: 25px;
}
#partner button {
  background-color: #2e3d5c;
  color: #fff;
  font-size: 1rem;
  border-radius: 10px;
  padding: 20px;
  margin-top: 25px;
  border: none;
  transition: all 0.3s;
}
#partner button:hover {
  background-color: #1d2c4b;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08);
}
#partner button:focus {
  outline: 0;
}
#spirit {
  display: flex;
  align-items: center;
}
#spirit p {
  max-width: 65%;
  text-align: center;
}
#spirit iframe {
  margin-bottom: 40px;
}
#spirit .iframe_wrapper {
  margin-top: 40px;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  width: 80%;
}
#spirit .iframe_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  border: 0;
}
#contact #contact_form {
  width: 75%;
}
#sustainability {
  align-items: flex-end;
}
body .flexer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  padding: 5%;
}
body section .content_box {
  background-color: #f1f1f1;
  border-radius: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5%;
  width: 60%;
  filter: drop-shadow(0 0 5px #888);
}
body section .wrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
body section .wrapper hr {
  margin: 10px;
  background-color: #000;
  height: 3px;
  width: 100%;
}
body section .wrapper h1 {
  text-align: center;
}
body #header #header_image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -10;
}
body #intro {
  margin: 35px;
  background-color: #f1f1f1;
}
body .no-pad {
  padding: 0;
}
body section:first-of-type {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex-direction: column;
}
@media screen and (min-width: 800px) {
  body .item {
    flex: 1;
  }
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;
}
.small {
  position: relative;
  height: 75%;
}
:root {
  --nav-bg-color-scrolled: #2e3d5c;
  --nav-text-color: #2e3d5c;
  --nav-text-color-scrolled: #fff;
  --nav-padding-top: 43px;
  --nav-padding-top-scrolled: 13px;
  --nav-height-scrolled: 63px;
  --logo-margin-top: -35px;
  --logo-margin-top-scrolled: -5px;
  --logo-size: 175px;
  --text-margin-top: 4px;
  --text-margin-top-scrolled: 12px;
}
.x-margin-top {
  margin-top: 25px;
}
nav {
  display: flex;
  width: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  padding: var(--nav-padding-top) 120px 15px 120px;
}
nav .nav {
  display: flex;
  justify-content: space-evenly;
  flex: 4;
}
nav .nav a {
  text-decoration: none;
}
nav .nav li {
  color: var(--nav-text-color);
  margin-top: var(--text-margin-top);
  width: 105px;
  text-align: center;
}
nav .nav .navItem_scroll {
  color: var(--nav-text-color-scrolled);
}
nav .nav .navItem_top {
  color: var(--nav-text-color);
}
nav .nav .logo {
  width: var(--logo-size);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--logo-margin-top);
}
nav .nav .logo_scroll {
  margin-top: var(--logo-margin-top-scrolled);
  animation-name: logoFadeIn;
  animation-duration: 0.5s;
}
nav .nav .logo_top {
  margin-top: var(--logo-margin-top);
  animation-name: logoFadeOut;
  animation-duration: 0.5s;
}
nav .nav:nth-of-type(2) {
  flex: none;
  width: var(--logo-size);
}
nav.nav_scroll {
  background-color: var(--nav-bg-color-scrolled);
  height: var(--nav-height-scrolled);
  padding-top: var(--nav-padding-top-scrolled);
  animation-name: navFadeIn;
  animation-duration: 0.5s;
}
nav.nav_scroll li {
  color: var(--nav-text-color-scrolled);
  animation-name: textFadeIn;
  animation-duration: 0.5s;
  margin-top: var(--text-margin-top-scrolled);
}
nav.nav_top {
  background-color: transparent;
  padding-top: var(--nav-padding-top);
  animation-name: navFadeOut;
  animation-duration: 0.5s;
}
nav.nav_top li {
  color: var(--nav-text-color);
  animation-name: textFadeOut;
  animation-duration: 0.5s;
}
#mobileNav {
  display: none;
}
@media screen and (max-width: 767px) {
  body section .content_box {
    width: 100% !important;
  }
  #desktopNav {
    display: none;
  }
  #mobileNav {
    width: 100%;
    height: 60px;
    padding: 20px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--nav-bg-color-scrolled);
    z-index: 2;
  }
  #mobileNav .scroll {
    background-color: #b2c3ba;
  }
  #mobileNav .top {
    background-color: default;
  }
  #mobileNav .logoMob {
    width: 100px;
    margin-top: -10px;
  }
  #mobileNav #menuToggle {
    display: block;
    position: absolute;
    float: left;
    top: 20px;
    left: 20px;
    max-height: 40px;
    max-width: 40px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }
  #mobileNav #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }
  #mobileNav #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: var(--nav-text-color-scrolled);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  #mobileNav #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }
  #mobileNav #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  #mobileNav #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: var(--nav-text-color-scrolled);
  }
  #mobileNav #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  #mobileNav #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }
  #mobileNav #menu {
    display: flex;
    flex-direction: column;
    width: 115vw;
    height: calc(115vh + 100px);
    margin: -100px 0 0 -50px;
    padding: 80px;
    z-index: 0;
    background: var(--nav-bg-color-scrolled);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(0, -100%);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  #mobileNav #menu li {
    padding: 10px 0;
    font-size: 1.8rem;
    color: var(--nav-text-color-scrolled);
    font-family: GearedSlab, "Arial Narrow", Arial, sans-serif;
    text-transform: uppercase;
  }
  #mobileNav #menu hr {
    margin: 10px 0 10px 0;
  }
  #mobileNav #menu .orientation .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: auto;
  }
  #mobileNav #menu .orientation .logo .logoImg {
    height: 100px;
  }
  #mobileNav a {
    text-decoration: none;
  }
  #mobileNav #menuToggle input:checked ~ ul {
    transform: none;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  #menu {
    padding: 80px;
    padding-right: 120px;
    padding-top: 125px;
  }
  #menu .orientation {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: space-evenly;
  }
  #menu .orientation .logo {
    width: 145px;
  }
  #menu li {
    padding: 0 20px;
  }
  #menu hr {
    margin: 20px 0 20px 0;
    display: none;
  }
  #menu .first {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  body #header .logoImg,
  body #header hr {
    width: 50%;
  }
}
@-moz-keyframes navFadeIn {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
}
@-webkit-keyframes navFadeIn {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
}
@-o-keyframes navFadeIn {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
}
@keyframes navFadeIn {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
}
@-moz-keyframes navFadeOut {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
    height: default;
  }
}
@-webkit-keyframes navFadeOut {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
    height: default;
  }
}
@-o-keyframes navFadeOut {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
    height: default;
  }
}
@keyframes navFadeOut {
  0% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  33% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
    height: var(--nav-height-scrolled);
  }
  66% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top-scrolled);
  }
  100% {
    background-color: var(--nav-bg-color-scrolled);
    padding-top: var(--nav-padding-top);
    height: default;
  }
}
@-moz-keyframes logoFadeIn {
  0% {
    margin-top: var(--logo-margin-top);
  }
  33% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top-scrolled);
  }
}
@-webkit-keyframes logoFadeIn {
  0% {
    margin-top: var(--logo-margin-top);
  }
  33% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top-scrolled);
  }
}
@-o-keyframes logoFadeIn {
  0% {
    margin-top: var(--logo-margin-top);
  }
  33% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top-scrolled);
  }
}
@keyframes logoFadeIn {
  0% {
    margin-top: var(--logo-margin-top);
  }
  33% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top-scrolled);
  }
}
@-moz-keyframes logoFadeOut {
  0% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  66% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top);
  }
}
@-webkit-keyframes logoFadeOut {
  0% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  66% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top);
  }
}
@-o-keyframes logoFadeOut {
  0% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  66% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top);
  }
}
@keyframes logoFadeOut {
  0% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  66% {
    margin-top: var(--logo-margin-top-scrolled);
  }
  100% {
    margin-top: var(--logo-margin-top);
  }
}
@-moz-keyframes textFadeIn {
  0% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
  33% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
}
@-webkit-keyframes textFadeIn {
  0% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
  33% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
}
@-o-keyframes textFadeIn {
  0% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
  33% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
}
@keyframes textFadeIn {
  0% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
  33% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
}
@-moz-keyframes textFadeOut {
  0% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
  66% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
}
@-webkit-keyframes textFadeOut {
  0% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
  66% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
}
@-o-keyframes textFadeOut {
  0% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
  66% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
}
@keyframes textFadeOut {
  0% {
    color: var(--nav-text-color-scrolled);
    margin-top: var(--text-margin-top-scrolled);
  }
  66% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top-scrolled);
  }
  100% {
    color: var(--nav-text-color);
    margin-top: var(--text-margin-top);
  }
}
